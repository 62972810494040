import React, { useState } from "react";

import { Grid, useMediaQuery, Button } from "@mui/material";
import mainLogo from "../../assets/e1logo.svg";
import { theme } from "../../styles/Theme";

import pitch_deck_app from "../../assets/pitch_deck_app.png";
import pre_seed_predictive_engine from "../../assets/pre_seed_predictive_engine.png";
import seed_predictive_engine from "../../assets/seed_predictive_engine.png";

const classes = {
  page: {
    width: "100vw",
    overflow: "auto",
    maxHeight: "100vh",
  },

  mainContainer: {
    backgroundColor: `${theme.palette.basic.main}`,

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  bold: {
    fontWeight: "bold",
  },

  logoWrapper: {
    paddingLeft: "2%",
    paddingRight: "2%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  dashboardTitle: {
    textAlign: "center",
    color: "white",
    fontWeight: "500",
  },
  toolSelection: {
    textAlign: "center",
    color: "white",
    fontWeight: "500",
  },
};

const MainPage = () => {
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dashboardItems = {
    "Pitch Deck App": "https://pitchdeck.e1models.co.uk",
    "Pre-Seed Predictive Engine": "https://preseed.e1models.co.uk",
    "Seed Predictive Engine": "https://seed.e1models.co.uk",
  };

  const renderDashboardOptions = () => {
    return (
      <Grid
        container
        spacing={3}
        style={{
          marginTop: "50px",
          userSelect: "none",
        }}
      >
        {Object.entries(dashboardItems).map(([optionName, optionUrl]) => {
          if (optionName === "Dashboard") {
            return null;
          }

          let optionImage;

          switch (optionName) {
            case "Pitch Deck App":
              optionImage = pitch_deck_app;
              break;
            case "Pre-Seed Predictive Engine":
              optionImage = pre_seed_predictive_engine;
              break;
            case "Seed Predictive Engine":
              optionImage = seed_predictive_engine;
              break;
            default:
              optionImage = null;
              break;
          }

          return (
            <Grid item xs={12} sm={12} md={6} lg={4} key={optionName}>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                {" "}
                <a
                  href={dashboardItems[optionName]}
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={optionImage}
                    alt={optionName}
                    style={{
                      width: "58%",
                      borderRadius: "25px",
                      cursor: "pointer",
                      border: "3.2px solid white",
                    }}
                    loading="lazy"
                    draggable="false"
                  />{" "}
                  <h2
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      cursor: "pointer",
                      color: "white",
                      fontWeight: "400",
                    }}
                  >
                    {optionName}
                  </h2>
                </a>
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderDashboardOptionsMobile = () => {
    return (
      <Grid
        container
        spacing={3}
        style={{
          userSelect: "none",
          marginTop: "15px",
        }}
      >
        {Object.entries(dashboardItems).map(([optionName]) => {
          return (
            <Grid item xs={12} key={optionName}>
              <a
                href={dashboardItems[optionName]}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="outlined"
                  style={{
                    color: "white",
                    borderColor: "white",
                    borderRadius: "60px",
                    padding: "10px 50px",
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                >
                  {optionName}
                </Button>
              </a>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={classes.logoWrapper}>
        <img
          src={mainLogo}
          alt="logo"
          style={{
            width: mobile ? "15rem" : "15rem",
            height: mobile ? "13rem" : "10rem",
            fill: "white",
            margin: mobile ? "auto" : null,
          }}
        />
      </Grid>
      <div
        style={{
          margin: "auto",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "4%",
        }}
      >
        <h1 style={classes.dashboardTitle}>DDVC Dashboard</h1>
        <h2 style={classes.toolSelection}>Select the tool you'd like to use</h2>
      </div>
      {!mobile && renderDashboardOptions()}
      {mobile && renderDashboardOptionsMobile()}
    </Grid>
  );
};

export default MainPage;
