import { createContext, useState, useEffect } from 'react';

 
export const SelectedPageContext = createContext({
    selectedPage: ['Dashboard',''],
    setSelectedPage: null,
  });
  
export const SelectedPageProvider = ({ children }) => {
  const [selectedPage, setSelectedPage] = useState(['Dashboard','']);

  useEffect(() => {
    console.log("Inside of useEffect")
    console.log(selectedPage)
    setSelectedPage(selectedPage);
  }, [selectedPage]);

  return (
    <SelectedPageContext.Provider value={{ selectedPage, setSelectedPage }}>
      {children}
    </SelectedPageContext.Provider>
);

};

export default SelectedPageContext;
